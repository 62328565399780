define("ember-template-compiler/lib/plugins/utils", ["exports", "ember-babel"], function (_exports, _emberBabel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isPath = isPath;
  _exports.isSubExpression = isSubExpression;
  _exports.isStringLiteral = isStringLiteral;
  _exports.trackLocals = trackLocals;

  function isPath(node) {
    return node.type === 'PathExpression';
  }

  function isSubExpression(node) {
    return node.type === 'SubExpression';
  }

  function isStringLiteral(node) {
    return node.type === 'StringLiteral';
  }

  function trackLocals() {
    var locals = new Map();
    var node = {
      enter: function enter(node) {
        for (var _iterator = (0, _emberBabel.createForOfIteratorHelperLoose)(node.blockParams), _step; !(_step = _iterator()).done;) {
          var _param = _step.value;

          var _value = locals.get(_param) || 0;

          locals.set(_param, _value + 1);
        }
      },
      exit: function exit(node) {
        for (var _iterator2 = (0, _emberBabel.createForOfIteratorHelperLoose)(node.blockParams), _step2; !(_step2 = _iterator2()).done;) {
          var _param2 = _step2.value;

          var _value2 = locals.get(_param2) - 1;

          if (_value2 === 0) {
            locals.delete(_param2);
          } else {
            locals.set(_param2, _value2);
          }
        }
      }
    };
    return {
      hasLocal: function hasLocal(key) {
        return locals.has(key);
      },
      node: node
    };
  }
});