define("ember-changeset/-private/err", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

  var Err = function Err(value, validation) {
    _classCallCheck(this, Err);

    this.value = value;
    this.validation = validation;
  };

  _exports.default = Err;
});