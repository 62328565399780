define("ember-changeset/-private/change", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

  var Change = function Change(value) {
    _classCallCheck(this, Change);

    this.value = value;
  };

  _exports.default = Change;
});